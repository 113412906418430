var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.certificateTableHeaders,"items":_vm.certificates,"item-key":"name","footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('certificate_table.table_title')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 primary--text",on:{"click":function($event){return _vm.openGenerateCertRequestDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.generate'))+" ")]),_c('v-btn',{staticClass:"mb-2 primary--text",on:{"click":function($event){return _vm.openUploadCertificateDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.upload'))+" ")])],1)]},proxy:true},{key:"item.params.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.addSpaceAfterComa(item.params.subject))+" ")]}},{key:"item.params.issuer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.addSpaceAfterComa(item.params.issuer))+" ")]}},{key:"item.params.san",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.addSpaceAfterComa(item.params.san))+" ")]}},{key:"item.params.valid_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseCertTime(item.params.valid_from))+" ")]}},{key:"item.params.valid_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseCertTime(item.params.valid_to))+" ")]}},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 mt-1",attrs:{"small":""},on:{"click":function($event){return _vm.downloadCertFile(item)}}},[_vm._v(" mdi-download ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteCertificate(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('dialog-delete',{on:{"confirm":_vm.deleteConfirm},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_vm._v(" "+_vm._s(_vm.$t('certificate_table.delete_dialog_text_before_cert_name'))+" "+_vm._s(_vm.deletedCertificate.name)+" "+_vm._s(_vm.$t('certificate_table.delete_dialog_text_after_cert_name'))+" ")]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogCertRequest),callback:function ($$v) {_vm.dialogCertRequest=$$v},expression:"dialogCertRequest"}},[_c('form-certificate-attrs',{on:{"cancel":_vm.cancelCertRequestDialog,"save":_vm.cancelCertRequestDialog}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogUploadCert),callback:function ($$v) {_vm.dialogUploadCert=$$v},expression:"dialogUploadCert"}},[_c('form-upload-certificate',{on:{"cancel":_vm.cancelUploadCertDialog,"save":_vm.cancelUploadCertDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }