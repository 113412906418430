<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('form_upload_cert.card_title') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formUploadCert"
          v-model="isValidForm"
        >
          <v-radio-group
            v-model="certificateType"
            @change="resetForm"
            row
            :rules="[rules.required]"
          >
            <v-radio
              :label="$t('form_upload_cert.radio_type_new_cert')"
              value="new certificate"
            ></v-radio>
            <v-radio
              :label="$t('form_upload_cert.radio_type_cor_csr_cert')"
              value="corresponding csr"
            ></v-radio>
          </v-radio-group>
          <div v-if="certificateType == 'new certificate'">
            <field-text-input-with-hint-icon
              v-model="certCorCsr.cert_name"
              :label="$t('form_upload_cert.field_name_label')"
              :hint="hints.hintNameField"
              :rules="[rules.required]"
            ></field-text-input-with-hint-icon>
            <field-file-input-with-hint-icon
              accept=".pem,.crt"
              :label="$t('form_upload_cert.field_cert_file_label')"
              :hint="hints.certHint"
              v-model="certCorCsr.certificate"
              :rules="[rules.required]"
            >
            </field-file-input-with-hint-icon>
            <field-file-input-with-hint-icon
              accept=".key"
              :label="$t('form_upload_cert.field_pk_file_label')"
              :hint="hints.pkHint"
              v-model="certCorCsr.key"
              :rules="[rules.required]"
            ></field-file-input-with-hint-icon>
            <field-file-input-with-hint-icon
              accept=".pem,.crt"
              :label="$t('form_upload_cert.field_intermediate_cert_file')"
              :hint="hints.intCertHint"
              v-model="certCorCsr.intermediate"
            ></field-file-input-with-hint-icon>
          </div>
          <div v-else-if="certificateType == 'corresponding csr'">
            <field-select-with-hint-icon
              v-model="certCorCsr.cert_name"
              :items="csrCertificates"
              :label="$t('form_upload_cert.field_choice_csr_cert')"
              item-text="name"
              item-value="name"
              :hint="hints.csrHint"
              :rules="[rules.required]"
            ></field-select-with-hint-icon>
            <field-file-input-with-hint-icon
              accept=".pem,.crt"
              :label="$t('form_upload_cert.field_cert_file_label')"
              :hint="hints.certHint"
              v-model="certCorCsr.certificate"
              :rules="[rules.required]"
            >
            </field-file-input-with-hint-icon>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 primary--text"
          @click="cancel"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!isValidForm"
          class="mb-2 primary--text"
          @click="save"
        >
          {{ $t('buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import FieldFileInputWithHintIcon from '../fields/FieldFileInputWithHintIcon.vue'
import FieldSelectWithHintIcon from '../fields/FieldSelectInputWithHintIcon.vue'
import FieldTextInputWithHintIcon from '../fields/FieldTextInputWithHintIcon.vue'

export default {
  name: 'FormUploadCert',
  components: {
    FieldFileInputWithHintIcon,
    FieldTextInputWithHintIcon,
    FieldSelectWithHintIcon,
  },
  data: () => ({
    isValidForm: false,
    certificateType: 'new certificate',
    defaultCertType: 'new certificate',
    newCert: {
      cert_name: '',
      certificate: null,
      key: null,
      intermediate: null,
    },
    defaultNewCert: {
      cert_name: '',
      certificate: null,
      key: null,
      intermediate: null,
    },
    certCorCsr: {
      cert_name: '',
      certificate: null,
    },
    defaultCertCorCsr: {
      cert_name: '',
      certificate: null,
    },
  }),
  computed: {
    /**
     * Подсказки к полям формы
     */
    hints() {
      return {
        hintNameField: this.$t('form_upload_cert.hints.hint_name_field'),
        certHint: this.$t('form_upload_cert.hints.hint_cert_file_field'),
        pkHint: this.$t('form_upload_cert.hints.hint_pk_file_field'),
        intCertHint: this.$t('form_upload_cert.hints.hint_int_cert_file_field'),
        csrHint: this.$t('form_upload_cert.hints.hint_choice_csr_field')
      }
    },
    /**
     * Правила валидации полей формы
     */
    rules() {
      return {
        required: value => !!value || this.$t('form_validation_errors.required'),
      }
    },
    csrCertificates() {
      return this.$store.getters['certificates/getCsrCertificates']
    },
  },
  methods: {
    /**
     * Очищает данные формы и генерирует событие 'cancel'
     */
    cancel() {
      this.resetForm()
      this.$emit('cancel')
    },
    /**
     * Отправляет запросы на создание сертификата и генерирует событие 'save'
     */
    save() {
      console.log('Save certificate form')
      let formData = new FormData()
      for ( var key in this.certCorCsr ) {
        formData.append(key, this.certCorCsr[key]);
      }
      if (this.certificateType == 'new certificate') {
        this.$store.dispatch('certificates/uploadNewCertificate', formData)
      } else if (this.certificateType == 'corresponding csr') {
        this.$store.dispatch('certificates/uploadCertificateCorCsr', formData)
      }
      this.resetForm()
      this.$emit('save')
    },
    /**
     * Приводит значения к дефолтным параметрам.
     */
    resetForm() {
      this.$nextTick(() => {
        this.certCorCsr = {...this.defaultCertCorCsr}
        this.newCertFiles = {...this.defaultNewCertFiles}
      })
      this.$refs.formUploadCert.resetValidation()
    },
  },
}
</script>

<style>

</style>