<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('certificate_form.card_title') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formCertificateAttrs"
          v-model="isValidForm"
        >
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.cert_name"
            :label="$t('certificate_form.field_name_label')"
            :hint="hints.hintNameField"
            :rules="[rules.required, isCertNameUniq]"
          ></field-text-input-with-hint-icon>
          <v-radio-group
            v-model="editedCertAttrs.cert_type"
            row
            :rules="[rules.required]"
            @change="changeCertType"
          >
            <template v-slot:label>
              <div>{{ $t('certificate_form.radio_field_cert_type_label') }}:</div>
            </template>
            <v-radio
            :label="$t('certificate_form.radio_ss_type')"
              value="certificate"
            ></v-radio>
            <v-radio
            :label="$t('certificate_form.radio_csr_type')"
              value="csr"
            ></v-radio>
          </v-radio-group>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.subject.CN"
            :label="$t('certificate_form.field_cn_label')"
            :hint="hints.hintCnField"
            :rules="[rules.required]"
          ></field-text-input-with-hint-icon>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.san"
            :label="$t('certificate_form.field_san_label')"
            :hint="hints.hintSanField"
          ></field-text-input-with-hint-icon>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.subject.O"
            :label="$t('certificate_form.field_o_label')"
            :hint="hints.hintOField"
          ></field-text-input-with-hint-icon>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.subject.OU"
            :label="$t('certificate_form.field_ou_label')"
            :hint="hints.hintOuField"
          ></field-text-input-with-hint-icon>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.subject.C "
            :label="$t('certificate_form.field_c_label')"
            :hint="hints.hintCField"
            :rules="[rules.iso3166]"
          ></field-text-input-with-hint-icon>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.subject.ST"
            :label="$t('certificate_form.field_st_label')"
            :hint="hints.hintStField"
          ></field-text-input-with-hint-icon>
          <field-text-input-with-hint-icon
            v-model="editedCertAttrs.subject.L"
            :label="$t('certificate_form.field_l_label')"
            :hint="hints.hintLField"
          ></field-text-input-with-hint-icon>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 primary--text"
          @click="cancel"
        >
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!isValidForm"
          class="mb-2 primary--text"
          @click="save">
            {{ $t('buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import FieldTextInputWithHintIcon from '../fields/FieldTextInputWithHintIcon.vue'
import iso3166 from '../../assets/iso_3166.json' 

export default {
  name: 'CertificateAttrForm',
  props: [
  ],
  components: {
    FieldTextInputWithHintIcon,
  },
  data: () => ({
    isValidForm: false,

    editedCertAttrs: {
      cert_name: '',
      cert_type: '',
      san: '',
      subject: {
        CN: '',
        O: '',
        OU: '',
        C: '',
        ST: '',
        L: '',
      }
    },
    defaultCertAttrs: {
      cert_name: '',
      san: '',
      cert_type: '',
      subject: {
        CN: '',
        O: '',
        OU: '',
        C: '',
        ST: '',
        L: '',
      }
    },
    errors: {},
  }),
  computed: {
    /**
     * Подсказки к полям формы
     */
    hints() {
      return {
        hintNameField: this.$t('certificate_form.hints.hint_name_field'),
        hintCnField: this.$t('certificate_form.hints.hint_cn_field'),
        hintSanField: this.$t('certificate_form.hints.hint_san_field'),
        hintOField: this.$t('certificate_form.hints.hint_o_field'),
        hintOuField: this.$t('certificate_form.hints.hint_ou_field'),
        hintCField: this.$t('certificate_form.hints.hint_c_field'),
        hintStField: this.$t('certificate_form.hints.hint_st_field'),
        hintLField: this.$t('certificate_form.hints.hint_l_field'),
      }
    },
    /**
     * Правила валидации полей формы
     */
    rules() {
      return {
        required: value => !!value || this.$t('form_validation_errors.required'),
        iso3166: value => !value || iso3166.findIndex((element) => element.code == value) != -1 || this.$t('form_validation_errors.iso_3166'),
      }
    },
  },
  methods: {
    /**
     * Проверяет что имя сертификата уникально.
     * @param {string} value Имя сертификата.
     */
    isCertNameUniq(value) {
      return this.$store.getters['certificates/isCertNameUniq'](value) || this.$t('form_validation_errors.uniq_cert_name')
    },
    /**
     * Очищает форму и приводит все ее параметры в дефолтное состояние.
     */
    eraseForm(){
      this.$nextTick(() => {
        this.editedCertAttrs = Object.assign({}, this.defaultCertAttrs)
        this.editedCertAttrs.subject = Object.assign({}, this.defaultCertAttrs.subject)
        this.errors = {}
      })
      this.$refs.formCertificateAttrs.resetValidation()
    },
    /**
     * Очищает форму и генерирует событие cancel
     */
    cancel() {
      this.eraseForm()
      this.$emit('cancel')
    },
    /**
     * Отправляет запрос на создание сертификата и генерирует событие save в котором возвращает параметры запроса.
     */
    save() {
      this.errors = {}
      console.log('Save certificate form')
      let ssRequest = this.generateCertificateRequest(this.editedCertAttrs)
      this.sendCertRequest(ssRequest)
      if (Object.keys(this.errors).length == 0) {
        this.$emit('save', ssRequest)
        this.eraseForm()
      }
    },
    /**
     * Отправляет запрос на создание сертификата
     * @param {ssRequest} ssRequest Параметры запроса на создание сертификата.
     */
    sendCertRequest(ssRequest) {
      console.log('Sending cert request.')
      if (ssRequest.cert_type == 'certificate') {
          this.$store.dispatch('certificates/generateSelfSignCertificate', ssRequest)
          .catch(error => {
            this.errors = error.response.data
            console.log(this.errors)
          })
      } else if (ssRequest.cert_type == 'csr') {
        this.$store.dispatch('certificates/generateCsr', ssRequest)
        .catch(error => {
          this.errors = error.response.data
          console.log(this.errors)
        })
      }
    },

    /**
     * Генерирует запрос на создание сертификата из данных формы.
     * @param {certAttrs} certAttrs Данные формы.
     * @returns Параметры для запроса создания сертификата.
     */
    generateCertificateRequest(certAttrs) {
      let subject = this.serializeSubject(certAttrs.subject)
      return {
        cert_name: certAttrs.cert_name,
        cert_type: certAttrs.cert_type,
        key_size: 2048,
        subject: subject,
        san: certAttrs.san
      }
    },
    /**
     * Сериализует данные формы в строку DN.
     * @param {subject} subject Данные формы для формирования строки DN.
     * @returns {string} строка DN.
     */
    serializeSubject(subject) {
      let subject_attrs = []
      for (let attr of Object.keys(subject)) {
        if (subject[attr]) {
          subject_attrs.push(`${attr}=${subject[attr]}`)
        }
      }
      return subject_attrs.join(',')
    },
    /**
     * Десериализует строку DN в параметры формы.
     * @param {string} subjectString Строка DN.
     */
    deserializeSubject(subjectString) {
      for (let attrString of subjectString.split(',')) {
        let attrArray = attrString.split('=')
        this.editedCertAttrs.subject[attrArray[0]] = attrArray[1]
      }
    },
    /**
     * Меняет тип сертификата для отображения правильных полей формы.
     * Поля формы зависят от типа сертификата.
     * @param {string} certType Тип сертификата. Может быть 'certificate' или 'csr'.
     */
    changeCertType(certType) {
      if (certType =='certificate') {
        this.getSelfSignCertAttrs().then((certAttrs) => {
          this.editedCertAttrs.subject.CN = certAttrs.CN
          this.editedCertAttrs.san = certAttrs.san
        })
      }
    },
    /**
     * Возвращает пред заполненные поля CN и San для полей формы.
     * Пред заполняются ip адреса устройства и его cn.
     */
    getSelfSignCertAttrs() {
      let pr1 = this.$store.dispatch('network/getIfaceWired')
      let pr2 = this.$store.dispatch('network/getIfaceModem')
      return Promise.all([pr1, pr2.catch(e => console.log(e))]).then(() => {
        let san = this.getSanString(this.$store.getters['network/getIpAddresses'])
        return {
          CN: `lightstream_player.local`,
          san: san,
        }
      })
    },
    /**
     * Формирует SAN строку из списка ip адресов.
     * @param {string[]} ipAddrs список ip адресов.
     * @returns {string} Строка в формате "IP=\<ip1>,IP=\<ip2>"
     */
    getSanString(ipAddrs) {
      let sanStrings = []
      for (let ip of ipAddrs) {
        if (ip) {
          sanStrings.push(`IP=${ip}`)
        }
      }
      return sanStrings.join(',')
    },
  },
}
</script>

<style>

</style>