<template>
  <div>
    <v-card
      width="45%"
      min-width="350px"
      outlined
      elevation="4"
      align="left"
    >
      <v-card-title>
        {{ $t('web_access_card.title') }}
        <v-spacer></v-spacer>
        <v-icon
          v-if="isAdministrator && isLicenseValid"
          small
          class="px-1"
          @click="editWebAccess"
        >mdi-pencil</v-icon>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formWebAccess"
          v-model="isValidWebAccessForm"
          :disabled="!(isAdministrator && isLicenseValid)"
          :readonly = "!settingsIsEditing"
        >
          <v-list>
            <v-list-item
            >
                <v-list-item-title>
                  {{ $t('web_access_card.http_port_title') }}
                </v-list-item-title>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="editedSettings.httpPort"
                  :rules="[rules.required, rules.portRange]"
                  dense
                  hide-details="auto"
                  :label="$t('web_access_card.network_port_text_field_label')"
                />
              </v-col>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="editedSettings.isHttpsEnabled"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ $t('web_access_card.enable_https_checkbox_title') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div v-if="editedSettings.isHttpsEnabled">
              <v-list-item>
                <v-list-item-title>
                  {{ $t('web_access_card.https_port_title') }}
                </v-list-item-title>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="editedSettings.httpsPort"
                    :rules="[rules.required, rules.portRange]"
                    dense
                    hide-details="auto"
                    :label="$t('web_access_card.network_port_text_field_label')"
                  />
                </v-col>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('web_access_card.certificate_title') }}
                </v-list-item-title>
                <v-col cols="6" sm="6" md="6">
                  <v-select
                    v-model="editedSettings.certificateName"
                    :items="certificates"
                    :label="$t('web_access_card.certificate_text_field_label')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-checkbox v-model="editedSettings.isRedirectHttpToHttpsEnabled"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ $t('web_access_card.redirect_checkbox_title') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-form>
      </v-card-text>
      <v-row
        v-if="settingsIsEditing"
        class="py-5 px-6"
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary--text"
          class="mb-2"
          @click="cancelSettingsEditing"
        >
        {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn
          color="primary--text"
          class="mb-2"
          :disabled="isApplyButtonDisabled()"
          @click="applySettingsEditing"
        >
        {{ $t('buttons.apply') }}
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
    data: () => ({
      settingsIsEditing: false,
      isValidWebAccessForm: false,
      editedSettings: {},
      rules: {
        required: value => !!value || 'Required.',
        portRange: value => Number(value) < 65535 && Number(value) > 0 || 'from 0 to 65535',
      },
    }),
    computed: {
      // Authorization
      isAdministrator() {
        return this.$store.getters.isAdministrator
      },
      isLicenseValid() {
        return this.$store.getters['license/isLicenseValid']
      },
      certificates() {
        return this.$store.getters['certificates/getValidCertificates']
      },
      currentSettings() {
        return this.$store.state.externalAccess.webAccessSettings
      },
    },
    methods: {
      /**
       * Проверяет нужно ли запретить нажимать кнопку apply в форме.
       * Кнопка должна быть неактивна если данные формы некорректны или не было внесено не одного изменения.
       * @returns {boolean} Должна ли кнопка быть неактивна.
       */
      isApplyButtonDisabled() {
        return !this.isValidWebAccessForm || !this.isSettingsEdited()
      },
      /**
       * Проверяет менялись ли данные формы.
       * @returns {boolean} Менялись ли данные формы.
       */
      isSettingsEdited() {
        return !(Object.entries(this.editedSettings).toString() === Object.entries(this.currentSettings).toString())
      },
      /**
       * Устанавливает параметр разрешающий редактирование формы.
       */
      editWebAccess() {
        this.settingsIsEditing = true
      },
      /**
       * Закрывает редактирование формы и приводит все значения к изначальным параметрам.
       */
      cancelSettingsEditing() {
        this.resetValues()
        this.settingsIsEditing = false
      },
      /**
       * Применяет изменение формы.
       */
      applySettingsEditing() {
        console.log(this.editedSettings)
        this.$store.dispatch('externalAccess/setWebAccessSettings', this.editedSettings)
        this.redirectPageToNewAddress()
        this.cancelSettingsEditing()
      },
      /**
       * Редиректит на новый адрес.
       */
      redirectPageToNewAddress() {
        let port = this.editedSettings.isHttpsEnabled ? this.editedSettings.httpsPort : this.editedSettings.httpPort
        let protocol = this.editedSettings.isHttpsEnabled ? 'https:' : 'http:'
        window.location.href = `${protocol}//${window.location.hostname}:${port}`;
      },
      /**
       * Приводит значения к ранее сохраненным параметрам.
       */
      resetValues() {
        this.editedSettings = Object.assign({}, this.currentSettings)
      },
    },
    beforeMount() {
      /**
       * Приводит значения к ранее сохраненным параметрам.
       */
      this.resetValues()
    },
    watch: {
      currentSettings() {
        this.resetValues()
      }
    },
}
</script>

<style>

</style>