<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :rules="rules"
    :hint="customHint"
    :error-messages="errorMessages"
    persistent-hint
  >            
    <v-icon
      slot="append-outer"
      color="#D3D3D3"
      @click="showHint"
    >
      mdi-help-circle-outline
    </v-icon>
  </v-text-field>
</template>

<script>
export default {
  name: 'v-text-field-with-hint-icon',
  props: [
    'value',
    'label',
    'rules',
    'hint',
    'errorMessages',
  ],
  data: () => ({
    customHint: '',
    defaultHint: '',
    isHintShowed: false,
  }),
  methods: {
    /**
     * Показывает подсказку.
     */
    showHint() {
      this.isHintShowed = !this.isHintShowed
      if (this.isHintShowed) {
        this.customHint = this.hint
      } else {
        this.customHint = this.defaultHint
      }
    },
  },
}
</script>

<style>

</style>