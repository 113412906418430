<template>
  <div>
    <v-row no-gutters class="d-flex justify-space-around py-5">
      <v-col >
        <web-access-card />
      </v-col>
    </v-row>
    <v-row no-gutters class="d-flex justify-space-around py-5">
      <v-col align="center">
        <v-card
        >
          <certificate-table />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import certificateTable from '@/components/CertificateComponents/CertificateTable'
import webAccessCard from '@/components/ExternalAccessComponents/WebAccessCard'

export default {
  components: {
      webAccessCard,
      certificateTable,
  }
}
</script>

<style>

</style>