<template>
  <div>
    <v-data-table
      :headers="certificateTableHeaders"
      :items="certificates"
      item-key="name"
      :footer-props="footerProps"
      class="elevation-4"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('certificate_table.table_title') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
            <v-btn
              class="mb-2 primary--text"
              @click="openGenerateCertRequestDialog()"
            >
              {{ $t('buttons.generate') }}
            </v-btn>
            <v-btn
              class="mb-2 primary--text"
              @click="openUploadCertificateDialog()"
            >
              {{ $t('buttons.upload') }}
            </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.params.subject="{ item }">
        {{ addSpaceAfterComa(item.params.subject) }}
      </template>
      <template v-slot:item.params.issuer="{ item }">
        {{ addSpaceAfterComa(item.params.issuer) }}
      </template>
      <template v-slot:item.params.san="{ item }">
        {{ addSpaceAfterComa(item.params.san) }}
      </template>
      <template v-slot:item.params.valid_from="{ item }">
          {{ parseCertTime(item.params.valid_from) }}
      </template>
      <template v-slot:item.params.valid_to="{ item }">
          {{ parseCertTime(item.params.valid_to) }}
      </template>
      <template v-slot:item.index="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="downloadCertFile(item)" class="mr-2 mt-1">
          mdi-download
        </v-icon>
        <v-icon small @click="deleteCertificate(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <dialog-delete v-model="dialogDelete" @confirm="deleteConfirm">
      {{ $t('certificate_table.delete_dialog_text_before_cert_name') }}
      {{ deletedCertificate.name }}
      {{ $t('certificate_table.delete_dialog_text_after_cert_name') }}
    </dialog-delete>
    <v-dialog v-model="dialogCertRequest" max-width="500px">
      <form-certificate-attrs @cancel="cancelCertRequestDialog" @save="cancelCertRequestDialog" />
    </v-dialog>
    <v-dialog v-model="dialogUploadCert" max-width="500px">
      <form-upload-certificate @cancel="cancelUploadCertDialog" @save="cancelUploadCertDialog" />
    </v-dialog>
  </div>
</template>

<script>
import FormCertificateAttrs from './FormCertificateAttrs.vue'
import FormUploadCertificate from './FormUploadCertificate.vue'
import DialogDelete from '../DialogDelete.vue'


export default {
  name: 'CertificateTable',
  components: {
    FormCertificateAttrs,
    DialogDelete,
    FormUploadCertificate,
  },
  data: function () {
    return {
      footerProps: {
        'items-per-page-options': [20],
        'disableItemsPerPage': false,
      },

      dialogDelete: false,
      dialogCertRequest: false,
      dialogUploadCert: false,
    
      deletedCertificate: {

      },
    }
  },
  computed: {
    // Authorization
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    certificates() {
      return this.$store.state.certificates.certificates
    },
    certificateTableHeaders() {
      return [
        { text: '#', value: 'index', width: '30px'},
        { text: this.$t('certificate_table.table_headers.name'), value: 'name' },
        { text: this.$t('certificate_table.table_headers.type'), value: 'cert_type' },
        { text: this.$t('certificate_table.table_headers.subject'), value: 'params.subject' },
        { text: this.$t('certificate_table.table_headers.san'), value: 'params.san' },
        { text: this.$t('certificate_table.table_headers.issuer'), value: 'params.issuer' },
        { text: this.$t('certificate_table.table_headers.valid_from'), value: 'params.valid_from' },
        { text: this.$t('certificate_table.table_headers.valid_to'), value: 'params.valid_to' },
        { text: this.$t('certificate_table.table_headers.actions'), value: 'actions' },
      ]
    },
  },
  methods: {
    /**
     * Помечает сертификат на удаление и открывает диалог с подтверждением удаления.
     * @param {certificate} certificate 
     */
    deleteCertificate(certificate) {
      this.deletedCertificate = {...certificate}
      this.dialogDelete = true
    },
    /**
     * Подтверждает удаление сертификата.
     */
    deleteConfirm() {
      this.$store.dispatch('certificates/deleteCertificate', this.deletedCertificate)
      console.log(`Delete certificate ${this.deletedCertificate.name}`)
      this.closeCertificateDeleteDialog()
    },
    /**
     * Закрывает диалог удаления сертификата.
     */
    closeCertificateDeleteDialog () {
      this.$nextTick(() => {
        this.deletedCertificate = {}
      })
      this.dialogDelete = false
    },
    /**
     * Открывает диалог загрузки сертификата
     */
    openUploadCertificateDialog() {
      this.dialogUploadCert = true
    },
    /**
     * Закрывает диалог загрузки сертификата.
     */
    cancelUploadCertDialog() {
      this.dialogUploadCert = false
    },
    /**
     * Открывает диалог генерации сертификата.
     */
    openGenerateCertRequestDialog() {
      this.dialogCertRequest = true
    },
    /**
     * Закрывает диалог генерации сертификата.
     */
    cancelCertRequestDialog () {
      this.dialogCertRequest = false
    },

    /**
     * Переводит время из timestamp в читаемую строку
     * @param {number} timestamp Время в виде timestamp
     * @returns {string} Возвращает время в виде строки или пустую строку.
     */
    parseCertTime(timestamp) {
      if (timestamp) {
        return (this.getDateFromTimestamp(timestamp))
      } else {
        return ''
      }
    },
    /**
     * Переводит время из timestamp в читаемую строку.
     * @param {number} timestamp Время в виде timestamp.
     * @returns {string} Возвращает время в виде строки.
     */
    getDateFromTimestamp(timestamp) {
      let date = new Date(timestamp * 1000)
      return  date.toDateString()
    },
    /**
     * Подключает к вебсокету для получения информации о сработавших триггерах.
     */
    connectToCertificateListWebsocket() {
      this.$store.dispatch('connectToWebsocket', 'ws/certificates').then( (wsConn) => {
        this.ws_connection_to_certificates_list = wsConn
        this.ws_connection_to_certificates_list.onmessage = (event) => {
          event.data.text().then(text => {
            let serialized_msg = JSON.parse(text)
            this.$store.commit('certificates/setWebCertificates', serialized_msg)
          })
        }
      })
    },
    /**
     * Скачивает файл сертификата.
     * @param {*} item Запись из таблицы сертификатов.
     */
    downloadCertFile(item) {
      let fileName = `${item.name}.crt`
      let certFile = new Blob([item.public_bytes])
      let url = window.URL.createObjectURL(certFile);
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    /**
     * Парсит строку добавляя пробелы после запятой.
     * Пробелы нужны для правильного переноса в слов в таблице.
     * @param {string} str Строка со словами разделенными запятой без пробелов.
     */
    addSpaceAfterComa(str) {
      return str ? str.split(',').join(', ') : ''
    },
  },
  /**
   * При создании страницы подключается к вебсокету.
   */
  mounted() {
    this.connectToCertificateListWebsocket()
  },
  /**
   * При уничтожении страницы отключается от вебсокета.
   */
  destroyed() {
    this.ws_connection_to_certificates_list.close()
  },
}
</script>

<style>
  .v-data-table-header th {
    white-space: nowrap;
  }
</style>