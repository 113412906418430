<template>
  <v-file-input
    :value="value"
    @change="inputFile"
    :accept="accept"
    :label="label"
    :rules="rules"
    :hint="customHint"
    persistent-hint
  >
    <v-icon
      slot="append-outer"
      color="#D3D3D3"
      @click="showHint"
    >
      mdi-help-circle-outline
    </v-icon>
  </v-file-input>
</template>

<script>
  export default {
    name: 'v-text-field-with-hint-icon',
    props: [
      'value',
      'label',
      'rules',
      'hint',
      'accept',
    ],
    data: () => ({
      customHint: '',
      defaultHint: '',
      isHintShowed: false,
    }),
    methods: {
      /**
       * Показывает подсказку.
       */
      showHint() {
        this.isHintShowed = !this.isHintShowed
        if (this.isHintShowed) {
          this.customHint = this.hint
        } else {
          this.customHint = this.defaultHint
        }
      },
      /**
       * Генерирует событие 'input' с файлом загруженным в поле.
       */
      inputFile(file) {
        this.$emit('input', file)
      },
    },
  }
  </script>

<style>

</style>